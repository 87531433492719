/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import React, { useState } from "react";
import { SketchPicker, BlockPicker, SwatchesPicker } from "react-color";

import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import { Button, Grid } from "@mui/material";
import Radio from "@mui/material/Radio";
import InputAdornment from "@mui/material/InputAdornment";
import LoadingButton from "@mui/lab/LoadingButton";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

function ChangePasswordForm({ loading, handleChange, submitHandler }) {
  const [selectedValue, setSelectedValue] = React.useState("a");

  const [fonts, setFonts] = useState([
    { value: "h5" },
    { value: "subtitle1" },
    { value: "subtitle2" },
    { value: "h4" },
  ]);
  const handleChangeRadio = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <form onSubmit={submitHandler}>
      <MDBox pt={4} pb={3} px={3}>
        <MDBox>
          <MDBox mb={2}>
            <MDInput
              type="password"
              name="current_password"
              required
              onChange={handleChange}
              label="Current password"
              fullWidth
            />
          </MDBox>

          <MDBox mb={2}>
            <MDInput
              type="password"
              name="new_password"
              required
              onChange={handleChange}
              label="New password"
              fullWidth
            />
          </MDBox>

          <MDBox mb={2}>
            <MDInput
              type="password"
              name="conf_password"
              required
              onChange={handleChange}
              label="Confirm Password"
              fullWidth
            />
          </MDBox>

          <LoadingButton
            loading={loading}
            style={{ color: "white" }}
            variant="contained"
            type="submit"
          >
            Save
          </LoadingButton>
        </MDBox>
      </MDBox>
    </form>
  );
}

export default ChangePasswordForm;
ChangePasswordForm.propTypes = {
  submitHandler: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
