import React from "react";
import { alpha, styled } from "@mui/material/styles";
import { Grid, LinearProgress, Stack, Tooltip, Typography } from "@mui/material";

const CustomLinerProgress = styled(LinearProgress)(({ theme }) => ({
  root: {
    height: "100px",
    borderRadius: 5,
  },
  "& .checkmark-bar": {
    height: 9,
    width: 1,
    backgroundColor: "currentColor",
    marginLeft: 1,
    marginRight: 1,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    height: "100px",
    backgroundColor: "#3ED625",
  },
}));

const UserProgessBar = ({ value, message }) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          marginTop: "0px",
          justifyContent: "space-between",
          gap: "10px",
        }}
      >
        <div style={{ width: "100%", alignSelf: "center" }}>
          <Tooltip title={message}>
            <CustomLinerProgress variant="determinate" value={value} />
          </Tooltip>
        </div>

        <Typography
          variant="subtitle2"
          sx={{
            fontStyle: "normal",
            fontSize: 15,
          }}
        >
          {value}%
        </Typography>
      </div>
    </>
  );
};

export default UserProgessBar;
