/* eslint-disable camelcase */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";

// utilities
import { stringToColor } from "../../../utilities";

function ProfilesList({ title, profiles, shadow, userViewHandler }) {
  const renderProfiles =
    profiles.length > 0 ? (
      profiles.map(({ username, phone_number, ...restOfDetails }) => (
        <MDBox key={username} component="li" display="flex" alignItems="center" py={1} mb={1}>
          <MDBox mr={2}>
            <Avatar style={{ background: stringToColor(username) }} alt="A" src="f" sx={{ mr: 2 }}>
              {username.charAt(0)}
            </Avatar>
            {/* <MDAvatar src={image} alt="something here" shadow="md" /> */}
          </MDBox>
          <MDBox
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
          >
            <MDTypography variant="button" fontWeight="medium">
              {username}
            </MDTypography>
            <MDTypography variant="caption" color="text">
              {username},{phone_number}
            </MDTypography>
          </MDBox>
          <MDBox ml="auto">
            <MDButton
              onClick={() => userViewHandler(restOfDetails, username, phone_number)}
              variant="text"
              color="info"
            >
              View
            </MDButton>
          </MDBox>
        </MDBox>
      ))
    ) : (
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        {" "}
        No available users{" "}
      </div>
    );

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {renderProfiles}
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default props for the ProfilesList
ProfilesList.defaultProps = {
  shadow: true,
  userViewHandler: null,
};

// Typechecking props for the ProfilesList
ProfilesList.propTypes = {
  title: PropTypes.string.isRequired,
  userViewHandler: PropTypes.func,
  profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  shadow: PropTypes.bool,
};

export default ProfilesList;
