import React, { useState, useEffect } from "react";
import { styled } from "@mui/system";
import TablePaginationUnstyled, {
  tablePaginationUnstyledClasses as classes,
} from "@mui/base/TablePaginationUnstyled";

import { useSnackbar } from "notistack";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import typography from "assets/theme/base/typography";

//import mui
import {
  FormControl,
  Box,
  Card,
  Grid,
  FilledInput,
  Input,
  InputAdornment,
  InputLabel,
  Chip,
  TextField,
  Badge,
  MenuItem,
  Select,
  Stack,
  Typography,
  LinearProgress,
  IconButton,
  Button,
  Backdrop,
  CircularProgress,
} from "@mui/material";
//import icons
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SsidChartIcon from "@mui/icons-material/SsidChart";

// react-router-dom components
import { useNavigate } from "react-router-dom";

import { generalPostApi } from "services";
import UserProgessBar from "components/UserProgressBar";
import { fDateTime, stringToColor } from "utilities";
import { generalGetApi } from "services";
import { formatCurrency } from "utilities";
import { generalPostApi2 } from "services";

function createData(name, calories, fat) {
  return { name, calories, fat };
}

const allTransactionData = [
  {
    date: "26-08-2021",
    user: "Peter",
    client: "Rescue Ai",
    account: "255654335651",
    status: "success",
    amount: "120,000",
  },
  {
    date: "26-08-2021",
    user: "Peter",
    client: "Rescue Ai",
    account: "255654335651",
    status: "success",
    amount: "120,000",
  },
  {
    date: "26-08-2021",
    user: "Peter",
    client: "Rescue Ai",
    account: "255654335651",
    status: "success",
    amount: "120,000",
  },
  {
    date: "26-08-2021",
    user: "Peter",
    client: "Rescue Ai",
    account: "255654335651",
    status: "success",
    amount: "120,000",
  },
];

const rows = [
  createData("Cupcake", 305, 3.7),
  createData("Donut", 452, 25.0),
  createData("Eclair", 262, 16.0),
  createData("Frozen yoghurt", 159, 6.0),
  createData("Gingerbread", 356, 16.0),
  createData("Honeycomb", 408, 3.2),
  createData("Ice cream sandwich", 237, 9.0),
  createData("Jelly Bean", 375, 0.0),
  createData("KitKat", 518, 26.0),
  createData("Lollipop", 392, 0.2),
  createData("Marshmallow", 318, 0),
  createData("Nougat", 360, 19.0),
  createData("Oreo", 437, 18.0),
].sort((a, b) => (a.calories < b.calories ? -1 : 1));

const blue = {
  200: "#A5D8FF",
  400: "#3399FF",
};

const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const Root = styled("div")(
  ({ theme }) => `
    table {
       
        font-size: 0.875rem;
        border-collapse: collapse;
        width: 100%;
    }

    td,
    th {
        border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
        text-align: left;
        padding: 6px;
      
    }
    

    th {
        background-color:#4CAF50;
        border-color:#4CAF50;
        color:white;
      
    }
    `
);

export default function Applications() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [users, setUsers] = useState([]);
  const [amount, setAmount] = useState();
  const [loading, setLoading] = useState(false);
  const { size } = typography;
  const userData = JSON.parse(sessionStorage.getItem("userData"));

  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const pullUsersHandler = async () => {
    setLoading(true);
    try {
      const settlements = await generalGetApi("order/today-settlements");

      setUsers(settlements.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const validateAdmin = (userId) => {
    if (userId === undefined || userId == null) {
      navigate("authentication/sign-in");
      return false;
    } else {
      return true;
    }
  };

  const getLink = async () => {
    setLoading(true);
    try {
      const transaction = await generalPostApi2(
        {
          amount: parseInt(amount),
          whatsappId: userData.id,
          phone_number: userData.contact,
          username: userData.username,
          phone_number_id: userData.phone_number_id,
        },
        "transaction"
      );

      setLoading(false);
      if (transaction.data.success) {
        window.location.href = transaction.data.payment_gateway_url;
      } else {
        enqueueSnackbar("Something went wrong!", { variant: "error" });
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const onChange = (e) => {
    setAmount(e.target.value);
  };

  useEffect(() => {
    validateAdmin(userData) ? pullUsersHandler() : navigate("/authentication/sign-in");
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box sx={{ background: "white", my: 3 }}>
        <Stack sx={{ mt: 3, ml: 3 }}>
          <Typography sx={{ mt: 3 }} variant="h3">
            <Badge color="secondary" badgeContent={users.length} max={999}>
              ADD FUNDS
            </Badge>
          </Typography>
        </Stack>

        <Stack sx={{ mx: 3 }} direction="row" justifyContent="flex-end">
          <Stack sx={{ mb: 2, width: "100%" }} direction="column" gap={2} alignItems="center">
            <Input
              onChange={onChange}
              sx={{
                mt: 3,
                // backgroundColor: "#F3F3F3",
                borderRadius: "5px",
                padding: "0px 10px 0px 10px",
                border: "1px solid #E9EEF4",
                width: "100%",
              }}
              placeholder="AMOUNT "
              disableUnderline
              id="input-with-icon-adornment"
            />
            <Button
              onClick={getLink}
              sx={{
                width: "100%",
                color: "white",
                mt: 3,
                backgroundColor: "rgba(76, 175, 80, 0.2)",
              }}
            >
              <p style={{ color: "black" }}>Get LINK</p>
            </Button>
          </Stack>
        </Stack>
      </Box>
      <Footer />

      <Backdrop
        sx={{ color: "#4CAF50", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </DashboardLayout>
  );
}
