/* eslint-disable prefer-arrow-callback */
/* eslint-disable no-unused-vars */
/* eslint-disable spaced-comment */
/* eslint-disable prettier/prettier */
/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, { useState, useEffect } from "react";

// react-router components
import { useLocation, Link, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Menu from "@mui/material/Menu";
import Slide from "@mui/material/Slide";
import Icon from "@mui/material/Icon";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";
// eslint-disable-next-line import/order
import MDTypography from "../../../components/MDTypography";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

// Notistack
import { useSnackbar } from "notistack";

// material icons
import LogoutIcon from "@mui/icons-material/Logout";
import KeyIcon from "@mui/icons-material/Key";
import ChangePasswordForm from "../../../components/ChangePasswordForm";
import { generalPostApi } from "services";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DashboardNavbar({ absolute, light, isMini }) {
  const userData = JSON.parse(sessionStorage.getItem("userData")) ?? { id: "a" };
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [openChangePass, setOpenChangePass] = useState(false);
  const [processLoading, setProcessLoading] = useState(false);
  const [passDetails, setPassDetails] = useState({ id: userData.id });

  const route = useLocation().pathname.split("/").slice(1);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    // function handleTransparentNavbar() {
    //   setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    // }

    /**
     The event listener that's calling the handleTransparentNavbar function when
     scrolling the window.
     */
    // window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    // handleTransparentNavbar();

    // Remove event listener on cleanup
    // return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
      <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });
  const onClickLogout = () => {
    sessionStorage.removeItem("userData");
    navigate("/authentication/sign-in");
  };

  const onClickPassword = () => {
    setOpenChangePass(true);
  };

  const handleCloseChangePass = () => {
    setOpenChangePass(false);
  };
  const handleChangePass = (e) => {
    setPassDetails({ ...passDetails, [e.target.name]: e.target.value });
  };

  const handleSubmitPass = async (e) => {
    e.preventDefault();
    setProcessLoading(true);

    if (passDetails.new_password !== passDetails.conf_password) {
      enqueueSnackbar("Passwords do not match", { variant: "error" });
      setProcessLoading(false);
      return;
    }

    try {
      const {
        data: { code, message },
      } = await generalPostApi(passDetails, "/auth/admin/change_password");
      if (code === 200) {
        setProcessLoading(false);
        enqueueSnackbar("Password update successfully!", { variant: "success" });
        handleCloseChangePass();
      } else {
        setProcessLoading(false);
        enqueueSnackbar(message, { variant: "error" });
        handleCloseChangePass();
      }
    } catch (error) {
      setProcessLoading(false);
      enqueueSnackbar("Something  went wrong", { variant: "error" });
      handleCloseChangePass();
    }
  };

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar
        sx={{
          flexDirection: "row",
          // alignItems: "flex-start",
          justifyContent: "space-between",
          pt: 0.5,
          pb: 0.5,
        }}
      >
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </MDBox>
        {isMini ? null : (
          <MDBox sx={{ display: "flex", justifyContent: "flex-end" }}>
            <MDBox
              sx={{ display: "flex", justifyContent: "flex-end" }}
              color={light ? "white" : "inherit"}
            >
              <IconButton onClick={handleOpenMenu} sx={navbarIconButton} size="small" disableRipple>
                <Icon sx={iconsStyle}>account_circle</Icon>
              </IconButton>

              <Menu
                anchorEl={openMenu}
                anchorReference={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                open={Boolean(openMenu)}
                onClose={handleCloseMenu}
                sx={{ mt: 2 }}
              >
                <Stack justifyContent="flex-start">
                  <IconButton
                    style={{ justifyContent: "flex-start" }}
                    onClick={onClickLogout}
                    sx={navbarIconButton}
                    size="small"
                    disableRipple
                  >
                    <LogoutIcon />
                    <MDTypography variant="button" fontWeight="light" color="text">
                      Logout
                    </MDTypography>
                  </IconButton>
                  <IconButton
                    onClick={onClickPassword}
                    sx={navbarIconButton}
                    size="small"
                    disableRipple
                  >
                    <KeyIcon />
                    <MDTypography variant="button" fontWeight="light" color="text">
                      change password
                    </MDTypography>
                  </IconButton>
                </Stack>
              </Menu>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              {/*<IconButton*/}
              {/*  size="small"*/}
              {/*  disableRipple*/}
              {/*  color="inherit"*/}
              {/*  sx={navbarIconButton}*/}
              {/*  onClick={handleConfiguratorOpen}*/}
              {/*>*/}
              {/*  <Icon sx={iconsStyle}>settings</Icon>*/}
              {/*</IconButton>*/}
              {/* <IconButton*/}
              {/*  size="small"*/}
              {/*  disableRipple*/}
              {/*  color="inherit"*/}
              {/*  sx={navbarIconButton}*/}
              {/*  aria-controls="notification-menu"*/}
              {/*  aria-haspopup="true"*/}
              {/*  variant="contained"*/}
              {/*  onClick={handleOpenMenu}*/}
              {/*>*/}
              {/*  <Icon sx={iconsStyle}>notifications</Icon>*/}
              {/*</IconButton>*/}
              {/*{renderMenu()}*/}

              <Dialog
                open={openChangePass}
                TransitionComponent={Transition}
                keepMounted
                fullWidth
                maxWidth="sm"
                onClose={handleCloseChangePass}
                aria-describedby="alert-dialog-slide-description"
              >
                <ChangePasswordForm
                  submitHandler={handleSubmitPass}
                  handleChange={handleChangePass}
                  loading={processLoading}
                />
              </Dialog>
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
