import { format, getTime, formatDistanceToNow } from "date-fns";

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), "dd MMMM yyyy");
}

export function fDateTime(date) {
  try {
    return format(new Date(date), "dd MMM yyyy HH:mm");
  } catch (error) {
    return "";
  }
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), "dd/MM/yyyy hh:mm p");
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function stringToColor(string) {
  try {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  } catch (e) {
    return "#ffa500";
  }
}

export function formatCurrency(data) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "KSH",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(data);
}
export function get_order_status_color(status) {
  if (status === "PROGRESS" || status === "in_progress") {
    return "In progress 🟠";
  }
  if (status === "PENDING") {
    return "Pending 🟡";
  }
  if (status === "COMPLETED") {
    return "Completed 🟢";
  }

  if (status === "PARTIAL") {
    return "Partial 🔵";
  }

  if (status === "CANCELED") {
    return "Canceled 🔴";
  }

  if (status === "PROCESSING") {
    return "Processing 🟣";
  }

  if (status === "failed") {
    return "Failed 🔴🔴 ";
  }
}

export function getYesterdayDate() {
  const today = new Date();

  // Subtract one day from today's date
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  // Extract the year, month, and day from yesterday's date
  const year = yesterday.getFullYear();
  const month = String(yesterday.getMonth() + 1).padStart(2, "0"); // January is 0, so we add 1
  const day = String(yesterday.getDate()).padStart(2, "0");

  // Format the date as YYYY-MM-DD
  return `${year}-${month}-${day}`;
}

export function getTodayDate() {
  // Get today's date
  const today = new Date();

  // Extract the year, month, and day
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // January is 0, so we add 1
  const day = String(today.getDate()).padStart(2, "0");

  // Format the date as YYYY-MM-DD
  return `${year}-${month}-${day}`;
}

export function getTomorrowDate() {
  const today = new Date();

  // Add one day to today's date
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  // Extract the year, month, and day from tomorrow's date
  const year = tomorrow.getFullYear();
  const month = String(tomorrow.getMonth() + 1).padStart(2, "0"); // January is 0, so we add 1
  const day = String(tomorrow.getDate()).padStart(2, "0");

  // Format the date as YYYY-MM-DD
  return `${year}-${month}-${day}`;
}
