/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// react imports
import React, { useEffect, useState } from "react";

// @mui material components
import {
  Grid,
  Card,
  Chip,
  Button,
  Box,
  Typography,
  Stack,
  Divider,
  FilledInput,
  Input,
  InputAdornment,
  InputLabel,
  Pagination,
  Backdrop,
  CircularProgress,
  Accordion,
} from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import typography from "assets/theme/base/typography";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data

import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";

import LoadingComponent from "components/LoadingComponent";
// react-router-dom components
import { useNavigate } from "react-router-dom";

// icons
import ApartmentTwoToneIcon from "@mui/icons-material/ApartmentTwoTone";
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";
// data
import userTableData from "components/UserTableData";

// API
import { pullStatsApi } from "services";
import { generalGetApi, generalPostApi, generalPostApi2, getStatsApi } from "../../services";
import HorizontalBarChart from "../../examples/Charts/BarCharts/HorizontalBarChart";
import StatisticsCard from "components/StatisticsCard";

import { formatCurrency } from "utilities";
import OrderCardComponent from "components/OrderCardComponent";

//import icons
import SearchIcon from "@mui/icons-material/Search";
import { useSnackbar } from "notistack";
import { fDateTime } from "utilities";

function Users() {
  const { sales, tasks } = reportsLineChartData;
  const { size } = typography;
  const userData = JSON.parse(sessionStorage.getItem("userData"));

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState([{}]);
  const [page1, setPage] = useState({ totalPages: 1, currentPage: 1 });
  const [query, setQuery] = useState();
  const [latestJobs, setLatestJobs] = useState([]);
  const { rows, columns, loadingTable } = userTableData();

  //   const pullStatsHandler = async () => {
  //     setLoading(true);
  //     try {
  //       const { data } = await generalGetApi(`wakala/stats?whatsappId=${userData.id}`);

  //       setLoading(false);
  //       setStats(data);
  //     } catch (error) {
  //       setLoading(false);
  //     }
  //   };

  //   const searchOrderHandler = async () => {
  //     setLoading(true);
  //     try {
  //       const { data } = await generalGetApi(`wakala/orders/id?id=${query}`);

  //       setLoading(false);
  //       setLatestJobs(data.data);
  //     } catch (error) {
  //       setLoading(false);
  //     }
  //   };

  const pullJobsHandler = async (page) => {
    setLoading(true);
    try {
      const { data } = await generalGetApi(
        `user?whatsappId=${userData.id}&page=${page}&perPage=10`
      );
      setPage({ totalPages: data.totalPages, currentPage: data.currentPage });

      console.log("dfsdfsdfsd", data);
      setLoading(false);
      setLatestJobs(data.data);
    } catch (error) {
      setLoading(false);
    }
  };

  const validateAdmin = (userId) => {
    if (userId === undefined || userId == null) {
      navigate("authentication/sign-in");
      return false;
    } else {
      return true;
    }
  };
  const changeHandler = (e) => {
    setQuery(parseInt(e.target.value));
  };
  const onPageChange = (e, page) => {
    pullJobsHandler(page);
  };

  useEffect(() => {
    validateAdmin(userData)
      ? pullJobsHandler(page1.currentPage)
      : navigate("/authentication/sign-in");
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box sx={{ background: "white", my: 2 }}>
        <Grid container spacing={2} sx={{ mt: 2, px: 2 }}>
          <Grid item sm={12} md={12}>
            <Stack spacing={2} direction="column">
              {loading ? (
                <Stack direction="row" sx={{ height: "200px" }}>
                  {" "}
                  LOADING.......{" "}
                </Stack>
              ) : latestJobs.length > 0 ? (
                latestJobs.map((item) => {
                  return (
                    <Stack direction="column">
                      <Stack spacing={3} direction="row">
                        <Typography sx={{ fontSize: size.xs }} variant="subtitle2">
                          <b>PHONE: </b>
                          {item.phone_number}
                        </Typography>

                        <Typography sx={{ fontSize: size.xs }} variant="subtitle2">
                          <b>SESSION: {fDateTime(item.session_start)}</b>
                        </Typography>
                      </Stack>
                      <Divider />
                    </Stack>
                  );
                })
              ) : (
                <Typography variant="subtitle1">No ORDERS</Typography>
              )}
            </Stack>
            <Stack sx={{ my: 5 }}>
              <Pagination onChange={onPageChange} count={page1.totalPages} />
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Backdrop
        sx={{ color: "#4CAF50", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Footer />
    </DashboardLayout>
  );
}

export default Users;
