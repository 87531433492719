/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// react imports
import React, { useEffect, useState } from "react";

// @mui material components
import {
  Grid,
  Card,
  Chip,
  Button,
  Box,
  Typography,
  Stack,
  Divider,
  FilledInput,
  Input,
  InputAdornment,
  InputLabel,
  Pagination,
  Backdrop,
  CircularProgress,
  TextField,
  Accordion,
} from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import typography from "assets/theme/base/typography";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data

import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";

import LoadingComponent from "components/LoadingComponent";
// react-router-dom components
import { useNavigate } from "react-router-dom";

// icons
import ApartmentTwoToneIcon from "@mui/icons-material/ApartmentTwoTone";
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";
// data
import userTableData from "components/UserTableData";

// API
import { pullStatsApi } from "services";
import { generalGetApi, generalPostApi, generalPostApi2, getStatsApi } from "../../services";
import HorizontalBarChart from "../../examples/Charts/BarCharts/HorizontalBarChart";
import StatisticsCard from "components/StatisticsCard";

import { formatCurrency } from "utilities";
import OrderCardComponent from "components/OrderCardComponent";

//import icons
import SearchIcon from "@mui/icons-material/Search";
import { useSnackbar } from "notistack";
import { fDateTime } from "utilities";

function Prices() {
  const { sales, tasks } = reportsLineChartData;
  const { size } = typography;
  const userData = JSON.parse(sessionStorage.getItem("userData"));

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState([{}]);
  const [page1, setPage] = useState({ totalPages: 1, currentPage: 1 });
  const [query, setQuery] = useState();
  const [latestJobs, setLatestJobs] = useState([]);
  const { rows, columns, loadingTable } = userTableData();

  const [open, setOpen] = React.useState(false);
  const [details, setDetails] = React.useState();
  const [dep, setDep] = React.useState(2);

  const changeHandler = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const handleClickOpen = (price, id) => {
    setDetails({ selling_price: price, id: id });
    setOpen(true);
  };

  const handleClose = () => {
    setDetails();
    setOpen(false);
  };
  const pullJobsHandler = async (page) => {
    setLoading(true);
    try {
      const { data } = await generalGetApi(`price?perPage=40&whatsappId=${userData.id}`);
      setPage({ totalPages: data.totalPages, currentPage: data.currentPage });

      console.log("dfsdfsdfsd", data);
      setLoading(false);
      setLatestJobs(data.data);
    } catch (error) {
      setLoading(false);
    }
  };

  const changePriceSubmitHandler = async () => {
    handleClose();

    setLoading(true);
    try {
      const { data } = await generalPostApi2(
        {
          selling_price: details.selling_price,
          whatsappId: userData.id,
          id: details.id,
        },
        "wakala/price/update"
      );

      console.log("dfsdfsdfsd", data);
      setLoading(false);
      enqueueSnackbar("Price updated successfully");
      setDep(dep + 1);
    } catch (error) {
      setLoading(false);
      enqueueSnackbar("Error ", { variant: "error" });
    }
  };
  const validateAdmin = (userId) => {
    if (userId === undefined || userId == null) {
      navigate("authentication/sign-in");
      return false;
    } else {
      return true;
    }
  };

  const onPageChange = (e, page) => {
    pullJobsHandler(page);
  };

  useEffect(() => {
    validateAdmin(userData)
      ? pullJobsHandler(page1.currentPage)
      : navigate("/authentication/sign-in");
  }, [dep]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box sx={{ background: "white", my: 2 }}>
        <Grid container spacing={2} sx={{ mt: 2, px: 2 }}>
          <Grid item sm={12} md={12}>
            <Stack spacing={2} direction="column">
              {loading ? (
                <Stack direction="row" sx={{ height: "200px" }}>
                  {" "}
                  LOADING.......{" "}
                </Stack>
              ) : latestJobs.length > 0 ? (
                latestJobs.map((item) => {
                  return (
                    <Stack spacing={1} direction="column">
                      <Stack spacing={2} direction="row">
                        <Typography sx={{ fontSize: size.xs }} variant="subtitle2">
                          <b>NAME: </b>
                          {item.service.name}
                        </Typography>
                        <Typography sx={{ fontSize: size.xs }} variant="subtitle2">
                          <b>BUYING PRICE: </b>
                          {formatCurrency(item.service.buy_price)}
                        </Typography>
                        <Typography sx={{ fontSize: size.xs }} variant="subtitle2">
                          <b>SELLING PRICE: </b>
                          {formatCurrency(item.selling_price)}
                        </Typography>
                        <Typography sx={{ fontSize: size.xs }} variant="subtitle2">
                          <b>CHARGES: </b>
                          {formatCurrency(item.bot_charge)}
                        </Typography>
                      </Stack>
                      <Stack spacing={3} direction="row">
                        <Typography sx={{ fontSize: size.xs }} variant="subtitle2">
                          <b>CHARGES: </b>
                          {formatCurrency(item.bot_charge)}
                        </Typography>
                        <Typography sx={{ fontSize: size.xs }} variant="subtitle2">
                          <b>FINAL PRICE: </b>
                          {formatCurrency(item.selling_price + item.bot_charge)}
                        </Typography>
                      </Stack>
                      <Stack>
                        <Button
                          sx={{ backgroundColor: "rgba(76, 175, 80, 0.2)" }}
                          variant="contained"
                          onClick={() => handleClickOpen(item.selling_price, item.id)}
                        >
                          CHANGE PRICE
                        </Button>
                      </Stack>
                      <Divider variant="fullWidth" />
                    </Stack>
                  );
                })
              ) : (
                <Typography variant="subtitle1">No ORDERS</Typography>
              )}
            </Stack>
            <Stack sx={{ my: 5 }}>
              <Pagination onChange={onPageChange} count={page1.totalPages} />
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Backdrop
        sx={{ color: "#4CAF50", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <form>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>EDIT PRICE</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Unaweza badirisha bei kwa wateja wako, kama vile kuwapa Offer
            </DialogContentText>

            <TextField
              value={details?.selling_price}
              autoFocus
              onChange={changeHandler}
              required
              margin="dense"
              name="selling_price"
              label="Selling price"
              type="number"
              fullWidth
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              sx={{ backgroundColor: "rgba(76, 175, 80, 0.2)" }}
              variant="contained"
              onClick={changePriceSubmitHandler}
            >
              CHANGE PRICE
            </Button>
          </DialogActions>
        </Dialog>
      </form>
      <Footer />
    </DashboardLayout>
  );
}

export default Prices;
