/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// react imports
import React, { useEffect, useState } from "react";

// @mui material components
import {
  Grid,
  Card,
  Chip,
  Button,
  Box,
  Typography,
  Stack,
  Divider,
  FilledInput,
  Input,
  InputAdornment,
  InputLabel,
  Pagination,
  Backdrop,
  CircularProgress,
  Accordion,
  IconButton,
  Hidden,
} from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import typography from "assets/theme/base/typography";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data

import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";

import LoadingComponent from "components/LoadingComponent";
// react-router-dom components
import { useNavigate } from "react-router-dom";

// icons
import ApartmentTwoToneIcon from "@mui/icons-material/ApartmentTwoTone";
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";
// data
import userTableData from "components/UserTableData";

// API
import { pullStatsApi } from "services";
import { generalGetApi, generalPostApi, generalPostApi2, getStatsApi } from "../../services";
import HorizontalBarChart from "../../examples/Charts/BarCharts/HorizontalBarChart";
import StatisticsCard from "components/StatisticsCard";

import { formatCurrency } from "utilities";
import OrderCardComponent from "components/OrderCardComponent";

//import icons
import SearchIcon from "@mui/icons-material/Search";
import { useSnackbar } from "notistack";
import { getTodayDate } from "utilities";
import { getTomorrowDate } from "utilities";

function Dashboard() {
  const { sales, tasks } = reportsLineChartData;
  const { size } = typography;
  const userData = JSON.parse(sessionStorage.getItem("userData"));

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState([{}]);
  const [liveStats, setLiveStats] = useState([{}]);
  const [stats2, setStats2] = useState([{}]);
  const [page1, setPage] = useState({ totalPages: 1, currentPage: 1 });
  const [query, setQuery] = useState();
  const [latestJobs, setLatestJobs] = useState([]);
  const [details, setDetails] = useState({ startDate: getTodayDate(), endDate: getTomorrowDate() });
  const { rows, columns, loadingTable } = userTableData();

  const changeDateHandler = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const pullStatsHandler = async () => {
    setLoading(true);
    try {
      const { data } = await generalGetApi(`wakala/stats?whatsappId=${userData.id}`);

      setLoading(false);
      setStats(data);
    } catch (error) {
      setLoading(false);
    }
  };

  const pullLiveStatsHandler = async () => {
    setLoading(true);
    try {
      const { data } = await generalGetApi(`wakala/live-stats?whatsappId=${userData.id}`);

      setLoading(false);
      setLiveStats(data);
    } catch (error) {
      setLoading(false);
    }
  };
  const filterStatsHandler = async () => {
    setLoading(true);
    try {
      const { data } = await generalGetApi(
        `wakala/stats-filter?whatsappId=${userData.id}&startDate=${details.startDate}&endDate=${details.endDate}`
      );

      setLoading(false);
      setStats2(data);
    } catch (error) {
      setLoading(false);
    }
  };

  const searchOrderHandler = async () => {
    setLoading(true);
    try {
      const { data } = await generalGetApi(
        `wakala/orders/id?id=${query}&whatsappId=${userData.id}`
      );

      setLoading(false);
      setLatestJobs(data.data);
    } catch (error) {
      setLoading(false);
    }
  };
  const handleRecreateOrder = async (data) => {
    console.log("sadasd", data);
    // return;
    if (data.url === undefined || data.url === "" || data.url === null) {
      enqueueSnackbar("link can not be empy", { variant: "error" });

      return;
    }

    setLoading(true);
    try {
      const response = await generalPostApi2(data, "wakala/orders/recreate");
      setLoading(false);
      console.log("res", response);
      console.log("res", response.status);
      if (response.status === 200) {
        enqueueSnackbar("Order recreated successfully");
      } else {
        enqueueSnackbar("Something went wrong, order not created", { variant: "error" });
      }
    } catch (err) {
      setLoading(false);
      enqueueSnackbar(err, { variant: "error" });
    }
  };

  const handleCancelOrder = async (data) => {
    console.log("sadasd", data);

    setLoading(true);
    try {
      const response = await generalPostApi2(data, "wakala/orders/cancel");
      setLoading(false);
      console.log("res", response);
      console.log("res", response.status);
      if (response.status === 200) {
        enqueueSnackbar("Order cancelled successfully");
      } else {
        enqueueSnackbar("Something went wrong, order not created", { variant: "error" });
      }
    } catch (err) {
      setLoading(false);
      enqueueSnackbar(err, { variant: "error" });
    }
  };

  const handleRefillOrder = async (data) => {
    console.log("sadasd", data);

    setLoading(true);
    try {
      const response = await generalPostApi2(data, "wakala/orders/refill");
      setLoading(false);
      console.log("res", response);
      console.log("res", response.status);
      if (response.status === 200) {
        enqueueSnackbar("Order Refilled successfully");
      } else {
        enqueueSnackbar("Something went wrong, order not created", { variant: "error" });
      }
    } catch (err) {
      setLoading(false);
      enqueueSnackbar(err, { variant: "error" });
    }
  };

  const pullJobsHandler = async (page) => {
    setLoading(true);
    try {
      const { data } = await generalGetApi(`wakala/orders?whatsappId=${userData.id}&page=${page}`);
      setPage({ totalPages: data.totalPages, currentPage: data.currentPage });

      console.log("dfsdfsdfsd", data);
      setLoading(false);
      setLatestJobs(data.data);
    } catch (error) {
      setLoading(false);
    }
  };

  const validateAdmin = (userId) => {
    if (userId === undefined || userId == null) {
      navigate("authentication/sign-in");
      return false;
    } else {
      return true;
    }
  };
  const changeHandler = (e) => {
    setQuery(parseInt(e.target.value));
  };
  const onPageChange = (e, page) => {
    pullJobsHandler(page);
  };

  useEffect(() => {
    validateAdmin(userData) ? pullStatsHandler() : navigate("/authentication/sign-in");
    pullJobsHandler(page1.currentPage);
    pullLiveStatsHandler();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Box sx={{ background: "white", my: 2 }}>
        <Card sx={{ boxShadow: "none", px: 2, py: 2, borderRadius: "5px" }}>
          <Stack spacing={2}>
            <Typography align="center" variant="h6">
              OVERALL STATS
            </Typography>
            <Grid spacing={2} container>
              {/* <Grid sx={{ display: "flex" }} item xs={6} md={3}>
                <StatisticsCard
                  stat={formatCurrency(stats.settlement_amount)}
                  title="Settlement amount"
                  icon={<StoreOutlinedIcon color="success" />}
                />
              </Grid> */}

              <Grid sx={{ display: "flex" }} item xs={6} md={4}>
                {/* <Divider sx={{ background: "black" }} orientation="vertical" flexItem /> */}
                <StatisticsCard
                  stat={formatCurrency(stats.total_amount)}
                  title="total amount"
                  icon={<StoreOutlinedIcon color="success" />}
                />
              </Grid>

              <Grid sx={{ display: "flex" }} item xs={6} md={4}>
                <Divider sx={{ background: "black" }} orientation="vertical" flexItem />

                <StatisticsCard
                  stat={stats.total_order_count}
                  title="Order count"
                  icon={<StoreOutlinedIcon color="success" />}
                />
              </Grid>
              <Grid sx={{ display: "flex" }} item xs={6} md={4}>
                <Hidden only="xs">
                  <Divider sx={{ background: "black" }} orientation="vertical" flexItem />
                </Hidden>
                <StatisticsCard
                  stat={stats.balance}
                  title="Balance"
                  icon={<StoreOutlinedIcon color="success" />}
                />
              </Grid>
            </Grid>
            <Divider sx={{ width: "100%" }} />

            <Typography align="center" variant="h6">
              TODAY STATS ( FROM 03:00AM)
            </Typography>

            <Grid spacing={2} container>
              <Grid sx={{ display: "flex" }} item xs={6} md={3}>
                <StatisticsCard
                  stat={formatCurrency(liveStats.total_order_count)}
                  title="Count"
                  icon={<StoreOutlinedIcon color="success" />}
                />
              </Grid>

              <Grid sx={{ display: "flex" }} item xs={6} md={3}>
                <Divider sx={{ background: "black" }} orientation="vertical" flexItem />
                <StatisticsCard
                  stat={formatCurrency(liveStats.total_sales)}
                  title="total Sales"
                  icon={<StoreOutlinedIcon color="success" />}
                />
              </Grid>

              <Grid sx={{ display: "flex" }} item xs={6} md={3}>
                {/* <Divider sx={{ background: "black" }} orientation="vertical" flexItem /> */}
                <StatisticsCard
                  stat={formatCurrency(liveStats.service_charge)}
                  title="Sercice Charge"
                  icon={<StoreOutlinedIcon color="success" />}
                />
              </Grid>
              <Grid sx={{ display: "flex" }} item xs={6} md={3}>
                <Divider sx={{ background: "black" }} orientation="vertical" flexItem />
                <StatisticsCard
                  stat={formatCurrency(liveStats.bot_charge)}
                  title="Bot charge"
                  icon={<StoreOutlinedIcon color="success" />}
                />
              </Grid>
              <Grid sx={{ display: "flex" }} item xs={6} md={3}>
                <StatisticsCard
                  stat={formatCurrency(liveStats.profit)}
                  title="Profit"
                  icon={<StoreOutlinedIcon color="success" />}
                />
              </Grid>
            </Grid>
          </Stack>
        </Card>
        <Grid container spacing={2} sx={{ mt: 2, px: 2 }}>
          <Grid item sm={12} md={12}>
            <Stack sx={{ mx: 3, my: 2 }} direction="row" justifyContent="flex-end">
              <Stack direction="row" gap={2} alignItems="center">
                <Button
                  size="small"
                  onClick={pullJobsHandler}
                  sx={{ color: "white", backgroundColor: "rgba(76, 175, 80, 0.2)" }}
                >
                  <p style={{ color: "black" }}>RESET</p>
                </Button>
                <Button
                  size="small"
                  onClick={searchOrderHandler}
                  sx={{ color: "white", backgroundColor: "rgba(76, 175, 80, 0.2)" }}
                >
                  <p style={{ color: "black" }}>search</p>
                </Button>
                <Input
                  onChange={changeHandler}
                  sx={{
                    // backgroundColor: "#F3F3F3",
                    borderRadius: "5px",
                    padding: "0px 10px 0px 10px",
                    border: "1px solid #E9EEF4",
                  }}
                  placeholder="WEKA ID #"
                  disableUnderline
                  id="input-with-icon-adornment"
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </Stack>
            </Stack>
            <Stack spacing={2} direction="column">
              {loading ? (
                <Stack direction="row" sx={{ height: "200px" }}>
                  {" "}
                  LOADING.......{" "}
                </Stack>
              ) : latestJobs.length > 0 ? (
                latestJobs.map((item) => {
                  return (
                    <OrderCardComponent
                      handleCancelOrder={handleCancelOrder}
                      handleRefillOrder={handleRefillOrder}
                      data={item}
                      reCreateOrderHandler={handleRecreateOrder}
                    />
                  );
                })
              ) : (
                <Typography variant="subtitle1">No ORDERS</Typography>
              )}
            </Stack>
            <Stack sx={{ my: 5 }}>
              <Pagination onChange={onPageChange} count={page1.totalPages} />
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Backdrop
        sx={{ color: "#4CAF50", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
